<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
            <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

            </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Account status</span>
            </v-card-title>
            <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Account status</label><span style="color: red;"> *</span>
                                <!-- <v-text-field class="text" v-model="accountStatus" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->
                                <v-text-field class="text" v-model.trim="accountStatus"  outlined dense :rules="[rules.required]" clearable
                                    required></v-text-field>
                                <v-btn color="success" @click="addaccountStatus">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Account status
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Account status</label><span style="color: red;"> *</span>
                                    <!-- <v-text-field class="text" v-model="accountStatusName" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->
                                    <v-text-field class="text" v-model.trim="accountStatusName" outlined dense :rules="[rules.required]" clearable
                                        required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" color="error" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" color="success" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <div v-if="loader">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
                <template v-if="accountStatus_list != null">
                    <v-data-table :headers="headers" :items="accountStatus_list" :search="search"
                        class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updateaccountStatus(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.accountStatusIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deleteAccountStatus(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
                </div>
            </v-card-text>
            </div>
        </v-card>
        <!-- <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay> -->
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        loader: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        accountStatus: null,
        accountStatus_SA: null,
        accountStatusId: null,
        accountStatusName: null,
        userType: null,
        edit_btn_load: false,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Account status',
                disabled: true,
                href: '',
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
        accountStatus_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "accountStatus" },
            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            this.loader = true;
            axios
                .post("/admin/accountStatusIsactive", item)
                .then(() => {
                    this.loader =false;
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Account status updated successfully...");
                })
                .catch((error) => {
                    this.loader = false;
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.loader =false;
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updateaccountStatus(item) {
            this.dialog = true;
            this.accountStatusName = item.accountStatus;
            this.accountStatusId = item.accountStatusId;
        },

        saveEdit(item) {
            console.log(item);
            // if(this.accountStatusName){
            // this.accountStatusName = this.accountStatusName.trim()
            // }
            const data = {
                accountStatusName: this.accountStatusName,
                accountStatusId: this.accountStatusId,
            };
            if (this.accountStatusName) {
                this.loader = true;
                axios
                    .post("/admin/editAccountStatus", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            this.loader = false;
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Account status updated successfully...");
                            this.onload();
                            this.dialog = false;
                        } else {
                            this.loader = false;
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.loader =false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Account status");
            }
        },
        deleteAccountStatus(item) {
            const data = {
                accountStatusId: item.accountStatusId,
            };
            axios
                .post("/admin/deleteAccountStatus", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Account Status Deleted Successfully  !!");
                        this.onload();
                    }
                    else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getAccountStatus")
                .then((res) => {
                    this.org = res.data.org;
                    this.accountStatus_list = res.data.accountStatus;
                    this.accountStatus_SA = res.data.accountStatus_SA
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addaccountStatus() {
            // if(this.accountStatus){
            // this.accountStatus = this.accountStatus.trim()
            // }
            const data = {
                accountStatus_name: this.accountStatus,
            };

            if (this.accountStatus) {
                this.loader = true;
                axios
                    .post("/admin/addAccountStatus", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.accountStatus_list = res.data.accountStatus_list;
                        if (res.data.msg == "success") {
                            this.loader = false;
                            this.showSnackbar("#4CAF50", "Account status added successfully...");
                            this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.loader = false;
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Account status");
                this.$refs.form.validate()
            }
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
